import React from "react"

import { useApp } from "../../../hooks/useApp"

export const withOrders = Component => ({ name = "Orders", page, uri }) => {
  const { globalStateReducer } = useApp()

  const title = page?.title

  const [{ orderDetailActive, activeOrderName }] = globalStateReducer

  const paths =
    orderDetailActive && activeOrderName
      ? [
          {
            title,
            url: uri,
          },
          {
            title: `Order ${activeOrderName}`,
          },
        ]
      : [
          {
            title,
          },
        ]

  Component.displayName = name
  return <Component title={title} uri={uri} paths={paths} />
}
