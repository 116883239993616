import React from "react"

import { withOrdersCard } from "./withOrdersCard"
import { OrdersList } from "../List/OrdersList"
import { OrdersDetail } from "../Detail/OrdersDetail"

export const OrdersCard = withOrdersCard(
  ({
    order,
    orders,
    loading,
    error,
    orderLoading,
    orderError,
    ordersUrl,
    additionalLoadingText,
    additionalNoOrdersText,
    additionalOrderNamePrefixText,
    additionalOrderPurchasedOnPrefixText,
    additionalTrackOrderButtonText,
    additionalViewOrderButtonText,
  }): JSX.Element => (
    <>
      {loading ? (
        <p>{additionalLoadingText}</p>
      ) : order?.id ? (
        <>
          <OrdersDetail order={order} loading={orderLoading} error={orderError} ordersUrl={ordersUrl} />
        </>
      ) : (
        <OrdersList
          orders={orders}
          loading={loading}
          error={error}
          additionalNoOrdersText={additionalNoOrdersText}
          additionalOrderNamePrefixText={additionalOrderNamePrefixText}
          additionalOrderPurchasedOnPrefixText={additionalOrderPurchasedOnPrefixText}
          additionalTrackOrderButtonText={additionalTrackOrderButtonText}
          additionalViewOrderButtonText={additionalViewOrderButtonText}
        />
      )}
    </>
  )
)
