import React from "react"

import { withOrdersDetail } from "./withOrdersDetail"
import {
  Wrapper,
  Return,
  Header,
  Main,
  Title,
  OrderNumber,
  Date,
  StatusWrapper,
  StatusBadge,
  Button,
  LineItems,
  LineItem,
  LineItemThumbnail,
  LineItemDetail,
  LineItemDetailTitle,
  LineItemDetailContent,
  PriceDetails,
  PriceDetail,
  PriceDetailTitle,
  PriceDetailAmount,
  ShippingDetail,
  ShippingDetailTitle,
  ShippingDetailContent,
  PriceTag,
  Text,
  Currency,
} from "./OrdersDetailStyles"
import { Image } from "../../../Image/Image"
import { Icon } from "../../../Icon/Icon"
import { Link } from "gatsby"

export const OrdersDetail = withOrdersDetail(
  ({
    ordersUrl,
    name,
    date,
    status,
    lineItems,
    subtotalPrice,
    totalPrice,
    totalShippingPrice,
    currencyCode,
    shippingAddress,
    url,
    additionalBackToOrdersText,
    additionalOrderNamePrefixText,
    additionalOrderPurchasedOnPrefixText,
    additionalQuantityPrefixText,
    additionalTrackOrderButtonText,
    additionalSubtotal,
    additionalShipping,
    additionalTotal,
    additionalShippingDetails,
  }) => (
    <Wrapper>
      <Return type={"button"} as={Link} to={ordersUrl}>
        <Icon name={"arrowLeftSmall"} />
        <span>&nbsp;{additionalBackToOrdersText}</span>
      </Return>
      <Header>
        <Title>
          <OrderNumber>
            {additionalOrderNamePrefixText}&nbsp;{name}
          </OrderNumber>
          <Date>
            {additionalOrderPurchasedOnPrefixText}&nbsp;{date}
          </Date>
        </Title>
        <StatusWrapper>
          <div>
            <StatusBadge>{status}</StatusBadge>
          </div>
          <Button as={"a"} href={url} target={"_blank"} colour={"black-reverse"}>
            {additionalTrackOrderButtonText}
          </Button>
        </StatusWrapper>
      </Header>
      <Main>
        <LineItems>
          {lineItems.map((lineItem, index) => (
            <LineItem key={lineItem.title + index}>
              <LineItemThumbnail>
                <Image image={lineItem.image} ratio={"1/1"} />
              </LineItemThumbnail>
              <LineItemDetail>
                <LineItemDetailTitle>{lineItem.title}</LineItemDetailTitle>
                <LineItemDetailContent>
                  <Text>{lineItem.selectedOptions}</Text>
                </LineItemDetailContent>
                <LineItemDetailContent>
                  <Text>
                    {additionalQuantityPrefixText}&nbsp;{lineItem.quantity}
                  </Text>
                  <PriceTag>{lineItem.discountedTotalPrice}</PriceTag>
                </LineItemDetailContent>
              </LineItemDetail>
            </LineItem>
          ))}
        </LineItems>
        <PriceDetails>
          <PriceDetail>
            <PriceDetailTitle>{additionalSubtotal}</PriceDetailTitle>
            <PriceDetailAmount>{subtotalPrice}</PriceDetailAmount>
          </PriceDetail>
          <PriceDetail>
            <PriceDetailTitle>{additionalShipping}</PriceDetailTitle>
            <PriceDetailAmount>{totalShippingPrice.includes("NaN") ? "Free" : totalShippingPrice}</PriceDetailAmount>
          </PriceDetail>
          <PriceDetail layout={"total"}>
            <PriceDetailTitle layout={"total"}>{additionalTotal}</PriceDetailTitle>
            <PriceDetailAmount layout={"total"}>
              <Currency>{currencyCode}&nbsp;&nbsp;&nbsp;</Currency>
              {totalPrice}
            </PriceDetailAmount>
          </PriceDetail>
        </PriceDetails>
      </Main>
      <ShippingDetail>
        <ShippingDetailTitle>{additionalShippingDetails}</ShippingDetailTitle>
        <ShippingDetailContent>
          <span>{shippingAddress.address1}</span>
          <span>
            {shippingAddress.city}&nbsp;{shippingAddress.province}
          </span>
          <span>{shippingAddress.zip}</span>
        </ShippingDetailContent>
      </ShippingDetail>
    </Wrapper>
  )
)
